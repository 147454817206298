@import './_variables';
@import './_mixins';

* {
  box-sizing: border-box;
  outline: none;
}

html,
body {
  height: 100%;
  overflow-x: hidden;
}

@font-face {
  font-weight: 800;
  font-family: 'DIN';
  font-style: normal;
  src: local('din'), url('../assets/fonts/dinm.woff') format('woff');
}

@font-face {
  font-weight: 300;
  font-family: 'DINR';
  font-style: normal;
  src: local('din'), url('../assets/fonts/dinRegular.woff') format('woff');
}

.ant-btn span,
.ant-drawer-title,
.ant-alert-message,
.ant-pro-card-title,
.ant-card-head-title,
.ant-statistic-title,
// .ant-descriptions-item-label,
.ant-tabs-tab .ant-tabs-tab-btn,
.ant-typography,
.ant-form-item-label label,
.ant-modal-confirm-body,
.ant-pro-table-list-toolbar-title,
.ant-table-thead .ant-table-cell,
.ant-form-item-control-input-content {
  font-family: 'DIN' !important;
}

body,
.ant-btn {
  font-family: 'DIN', sans-serif !important;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// input,
// // .ant-table-row,
// // .ant-table-cell,
// // .ant-table-cell *,
// .ant-select-selection-placeholder {
//   font-family: 'DINR', sans-serif !important;
//   text-rendering: optimizeLegibility;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
// }

body {
  position: relative;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $main-text-color;
  background-color: $main-background-color;
  font-size: $main-text-size;
}

a {
  color: #00aec3;
}

a:active {
  color: #0095a7;
}

@media (max-width: $phone-size) {
  .ant-pro-page-container-children-content {
    padding: 5px !important;
  }
}

.ant-layout .ant-layout-content {
  flex: auto;
  margin-bottom: 20px;
  min-height: fit-content !important;
}

.leaflet-container {
  min-height: 300px;
}

.ant-page-header.ant-pro-page-container-warp-page-header {
  background: white;
  margin-bottom: 15px;
  border-bottom: 1px solid whitesmoke;
}

.page-tabs-card .ant-tabs-nav::before {
  border: 0px;
}

.ant-layout-sider-children {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.ant-pro-table-column-setting-overlay {
  min-width: 300px;
}

.ant-pro-table-column-setting-overlay .ant-popover-inner-content {
  min-width: 265px;
}

.ant-alert-info {
  background-color: #06aec3d9;
  border: 1px solid #48bacc;

  & .ant-alert-message,
  & .anticon-info-circle {
    color: white;
  }
}

.ant-alert-info.light {
  background-color: white;
  border: 1px solid #00aec3;

  & .ant-alert-message,
  & .anticon-info-circle {
    color: #00aec3;
  }
}

// .ant-form-item-explain-success {
//   font-size: 12px;
//   margin-top: 5px;
// }

.ant-list-item-meta-title {
  margin-top: 0px !important;
}

.ant-table-wrapper .ant-table-row-expand-icon:active {
  color: #06aec3 !important;
}

.ant-table-wrapper .ant-table-row-expand-icon:focus,
.ant-table-wrapper .ant-table-row-expand-icon:hover {
  color: #01a2b6 !important;
}

.custom-tabs-selector > .ant-tabs-nav {
  &::before {
    border-bottom: none !important;
  }
  background: white;
  padding: 0px 20px;
  border-radius: 7px;
  border: 1px solid whitesmoke;
  margin-bottom: 20px;
}

.ant-pro-form-login-page {
  background-size: cover;
  background-position-y: bottom;
  background-position-x: right;
}

.ant-pro-steps-form-step-active {
  max-width: 88vw !important;
}

.ant-page-header-heading-title {
  white-space: break-spaces !important;
}

input::placeholder,
.ant-input-disabled.ant-input[disabled],
.ant-select-selection-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #5d5d5d !important;
  opacity: 1; /* Firefox */
}

@media (min-width: 700px) {
  .ant-pro-table-list-toolbar-left {
    max-width: calc(100% - 350px) !important;
  }

  .ant-pro-steps-form {
    .ant-row-stretch > .ant-col {
      flex: 1;
    }
  }
}

.ant-menu-submenu .ant-pro-base-menu-item-text,
.ant-menu-item-active,
.ant-menu-item-selected {
  color: rgb(23, 23, 23) !important;
}

.ant-table-cell.readonly-column {
  background-color: #fafafa;
}

.estado-resultado-item {
  td:first-child {
    background: #8bc34a3d !important;
  }
}

.estado-resultado-final {
  td:first-child {
    background: #ff980091 !important;
  }
}

.estado-resultado {
  td:first-child {
    background: #ffbf0030 !important;
  }
}

.row-highlighted {
  background: #ffbf0030 !important;

  &:hover,
  .ant-table-column-sort,
  .ant-table-cell-row-hover {
    background: #ffbf0030 !important;
  }
}
